<template>
  <div style="background-color: #f4f4f4;">
    <heard class="hearder" />
    <div class="main">
      <div class="main-title" @click="goShopHome()">
        &lt; 店铺管理
      </div>
      <div class="main-subtitle">
        <div class="main-subtitle-left">
          选择套餐
        </div>
        <el-button class="main-subtitle-right">
          套餐管理
        </el-button>
      </div>
      <div id="card" class="main-content">
        <div class="flex-table">
          <StorePackageMobileTable :show-price-btn="true" class="show-mtable" />
        </div>
        <!-- show -->
        <div class="show-table">
          <div class="main-content-card">
            <div style="display:flex;" :style="{ height:showText?'466px':'250px' }">
              <div class="main-content-card-one" style="transition: height 0s ease-in-out;">
                <div>
                  <div class="content-txt" style="margin-bottom: 10px">
                    VLLSHOP
                  </div>
                  <div class="content-txt">
                    功能细则
                  </div>
                </div>
              </div>
              <!-- 其他卡片 -->
              <div
                v-for="(item, index) in list"
                :key="index"
                :style="item.color"
                class="main-content-card-other cbd"
                :class="{tobuy:!showText}"
                @click="toBuyMini(index,item)"
              >
                <div class="main-content-card-other-col">
                  <div class="card-title">
                    {{ item.varName }}
                  </div>
                  <div class="card-title">
                    {{ item.enName }}
                  </div>
                  <div
                    v-show="showText"
                    style="margin-top: 64px;height: 100px;text-align: center; display: inline-flex;
                      flex-direction: column;
                      justify-content: center;
                      font-size: 15px;"
                  >
                    <p>{{ item.contentone }}</p>
                    <p>{{ item.contenttwo }}</p>
                    <p>{{ item.contentthree }}</p>
                    <p>{{ item.contentfour }}</p>
                    <p>{{ item.contentfive }}</p>
                    <p>{{ item.contentsix }}</p>
                  </div>
                  <!-- 大小符号 -->
                  <!-- <div style="display: flex">
                  <div>¥</div>
                  <div class="card-price">
                    180
                    <div class="card-price-sub">
                      /月
                    </div>
                  </div>
                </div> -->
                  <div v-show="showText" class="card-price-month" style="margin-top: 60px">
                    <!-- ¥400/月 -->
                    {{ item.price==0?"免费":item.price }}
                  </div>
                  <div v-show="showText" class="card-price-month" style="margin-bottom: 36px">
                    <!-- 月付 ¥480/月 -->
                    {{ item.price2==0?"&nbsp;":item.price2 }}
                  </div>
                  <el-button
                    v-show="showText"
                    :class="comboId > item.comboId?'nobuybtn' : 'buybtn'"
                    :disabled="comboId > item.comboId"
                    @click="toPayPage(index)"
                  >
                    {{ comboId > item.comboId? '你已购买更高版本' : item.butLabel }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- show -->
        <div class="mytable">
          <div class="show-table">
            <!-- 介绍 -->
            <detail-table :columns="columns" :data="data" :show-header="false" />

            <!-- 店铺管理 -->
            <detail-table :columns="columns1" :data="data1" header-title="店铺管理" />

            <!-- 一件代发 -->
            <detail-table :columns="columns1" :data="data2" header-title="一件代发" />

            <!-- 广告投放 -->
            <detail-table :columns="columns1" :data="data3" header-title="广告投放" />

            <!-- 站内转化 -->
            <detail-table :columns="columns1" :data="data4" header-title="站内转化" />

            <!-- 特色功能 -->
            <detail-table :columns="columns1" :data="data5" header-title="特色功能" />

            <!-- 云手机 -->
            <detail-table :columns="columns1" :data="data33" header-title="云手机" />

            <!-- 贴心服务 -->
            <detail-table :columns="columns1" :data="data6" header-title="贴心服务" />

            <p class="tipText">
              *以上价格套餐内容自2021年8月23日起生效。
            </p>
          </div>
          <!-- ----------去除------ -->
          <div class="bottom-box">
            <div class="bottom-box-content-box">
              <div
                style="
                  height: 217px;
                  width: 300px;
                "
              >
                <img
                  style="height: 217px;
                  width: 300px;"
                  src="../../../assets/img/StorePackage/StorePackage.png"
                  alt=""
                >
              </div>
              <div class="bottom-box-content-box-r">
                <div class="bottom-box-content-box-r-left">
                  <div
                    class="bootom-z"
                  >
                    <div
                      class="bottom-one"
                    >
                      专业版
                    </div>
                    <div
                      style="
                        margin-bottom: 14px;
                        font-size: 18px;
                        line-height: 32px;
                        color: #fff;
                        opacity: 0.5;
                        align-self: start;
                      "
                    >
                      品牌出海与大体量卖家优选，充分满足您定制化需求
                    </div>
                    <div style="align-self: start">
                      <div class="cbefore">
                        功能定制化
                      </div>
                      <div class="cbefore">
                        服务定制化
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-box-content-box-r-right">
                  <div>
                    <div style="align-self: end; color: #fff">
                      联系客户代表定制套餐
                    </div>
                    <div
                      style="
                        align-self: end;
                        margin-top: 6px;
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 42px;
                        color: #fff;
                      "
                    >
                      0755-83899969
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import detailTable from '@/components/StorePackageTable/detailTable.vue'
import StorePackageMobileTable from '@/components/StorePackageTable/mobiletable'
import { getPriceList, getUserInfo } from '@/api/register'
import heard from '../components/heard.vue'
import { setShopPackage } from '@/utils/cookie.js'
import {
  columns,
  columns1,
  data,
  data1,
  data2,
  data3,
  data33,
  data4,
  data5,
  data6
} from './StorePackageData'
// data
export default {
  components: {
    heard,
    StorePackageMobileTable,
    detailTable
  },
  data() {
    return {
      // 选择限制
      comboId: -1,
      showText: true,
      data,
      data1,
      data2,
      data33,
      data3,
      data4,
      data5,
      data6,
      columns,
      columns1,
      list: [
        {
          color: 'background-color: #EE9CA7;',
          comboId: 1,
          varName: '爱心版',
          enName: 'Love version',
          contentone: 'Vllshop响应国家号召',
          contenttwo: '针对应届大学生、',
          contentthree: '退伍军人、残疾人',
          contentfour: '等特殊群体的',
          contentfive: '就业和创业',
          contentsix: '提供爱心版免费服务',
          price: '0',
          price2: '0',
          butLabel: '申请套餐',
          type: 'V1'
        },
        {
          color: 'background-color: #4B6CB7;',
          comboId: 2,
          varName: '基础版',
          enName: 'Basic Edition',
          contentone: '绝佳的启动方式',
          contenttwo: '适合新手卖家快速上手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '4300元/年',
          price2: '430元/月',
          butLabel: '购买套餐',
          type: 'V2'
        },
        {
          color: 'background-color: #19397B;',
          comboId: 3,
          varName: '专业版',
          enName: 'Professional Edition',
          contentone: '轻松运营销量节节攀升',
          contenttwo: '晋级跨境电商职业选手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '8800元/年',
          price2: '880元/月',
          butLabel: '购买套餐',
          type: 'V3'
        },
        {
          color: 'background-color: #121A43;',
          comboId: 4,
          varName: '旗舰定制版',
          enName: 'Fagship',
          contentone: 'Vllshop给买家提供旗舰定',
          contenttwo: '制版独立站服务',
          contentthree: '提供零到一一站式服务',
          contentfour: '独立站销售培训',
          contentfive: '运营经验分享',
          contentsix: '专属咨询顾问一对一服务',
          price: '89999年/元',
          price2: '0',
          butLabel: '购买套餐',
          type: 'V4'
        }
      ]
    }
  },
  mounted() {
    const m = function(e) {
      e.preventDefault()
    }
    document.body.style.overflow = '' // 出现滚动条
    document.removeEventListener('touchmove', m, { passive: true })

    const card = document.getElementById('card')
    const html = document.documentElement
    const header = document.getElementsByClassName('hearder-content')[0]
    // 回退到0
    this.$nextTick(function() {
      html.scrollTop = 0
    })
    console.log(html.scrollTop, 'html.scrollTop')
    window.addEventListener('scroll', () => {
      this.showText = html.scrollTop <= card.offsetTop - (header.clientHeight + header.clientTop)
    })

    // 获取套餐价格
    this.getPriceList()

    // 套餐不可降级
    getUserInfo().then((res) => {
      if (res.code === 0) {
        const userInfo = res.data
        this.comboId = Number(userInfo.comboId)
        if (this.comboId === 5) {
          this.comboId = -1
        }
      }
    })
  },
  destroyed() {
    window.removeEventListener('scroll', () => {
    })
  },
  methods: {
    // 获取套餐列表
    getPriceList() {
      getPriceList().then((res) => {
        if (res.code === 0) {
          const priceList = res.data.records
          this.list.map((item) => {
            priceList.map((resItem) => {
              if (item.type === resItem.code) {
                item.price = resItem.yearPrice === 0 ? resItem.yearPrice : `${resItem.yearPrice}元/年` // 年
                item.price2 = resItem.monthPrice === 0 ? resItem.monthPrice : `${resItem.monthPrice}元/月` // 年
                // 旗舰版额外处理
                if (item.type === 'V4') {
                  item.price2 = '0' // 旗舰版没有月付
                }
              }
            })
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    toPayPage(index) {
      if (index === 0) {
        this.$router.push({ name: 'loveVersion' })
        return
      }
      const obj = {}
      obj.name = this.list[index].varName
      obj.priceMonth = this.list[index].price2.replace(/[^\d.]/g, '')
      obj.nameYear = this.list[index].price.replace(/[^\d.]/g, '')
      obj.type = this.list[index].type
      setShopPackage(obj)
      this.$router.push({ name: 'OrderPay' })
      // console.log(JSON.parse(getShopPackage()).name, 'ccc')
    },
    goShopHome() {
      this.$router.push({ name: 'shopManagement' })
    },

    // 卡片缩小时的点击事件
    toBuyMini(index, item) {
      if (this.showText) {
        return
      }

      if (this.comboId > item.comboId) {
        this.$message.warning('你已购买更高版本')
        return
      }
      this.toPayPage(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.cbd{
  transition: height 0s ease-in-out
}
// 适配

.show-mtable{
  display: unset;
}
.show-table{
  display: none;
}

.buybtn{
  width: 160px;
  color: #356dff;
  background-color: #fff !important;
  border: none;
}
.nobuybtn{
  width: 160px;
  color: #fff;
  background-color: transparent !important;
  border: none;
}
::v-deep .el-button.is-disabled:hover:hover{
  color: #fff !important;
}
.hearder{
  width: 100% !important;
  position: sticky;
  top: 0;
  z-index: 1000 !important;
}

.cbefore {
  display: inline-flex;
  margin-right: 30px;
  color: #9399a5;
  font-size: 18px;
  line-height: 20px;
}
.cbefore::before {
  width: 6px;
  height: 6px;
  margin-top: 7px;
  margin-right: 12px;
  background: #9399a5;
  border-radius: 50%;
  content: "";
}
// 左侧文字
.content-txt {
  color: #191a1c;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
}
// 卡片内容
.card-title {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.card-price {
  align-items: center;
  height: 56px;
  font-weight: 500;
  font-size: 36px;
  font-family: "Roboto",serif;
}
.card-price-sub {
  display: inline-flex;
  height: 50px;
  margin-left: 4px;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  opacity: 0.8;
}
.card-price-month {
  //   margin-bottom: 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
// 底部
.tipText {
  padding-top: 8px;
  padding-bottom: 20px;
  color: #7a8499;
  font-size: 12px;
  font-family: PingFang SC,serif;
  background-color: #f4f4f4;
}
.bottom-box {
  padding-bottom: 20px;
  min-height: 237px;
  background-color: #f4f4f4;
  &-content-box {
    // 内容盒子
    min-height: 217px;
    display: flex;
    background-color: #1e2b45;
    &-r {
      display: flex;
      width: 963px;
      justify-content: space-between;
      &-left {
        min-width: 300px;
        // background: rgb(255, 255, 255);
        display: flex;
        align-items: center;
      }
      &-right {
        min-width: 300px;
        // background: rgb(138, 107, 252);
        display: flex;
        align-items: center;
        padding-right: 50px;
        text-align: right;
      }
    }
  }
}
.main {
  width: 100%;
  max-width: 1920px;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  &-title {
    margin-top: 24px;
    width: 100%;
    max-width: 1263px;
    cursor: pointer;
    text-align: left !important;
  }
  &-subtitle {
    margin-top: 8px;
    display: flex;
    width: 100%;
    max-width: 1263px;
    align-items: flex-start;
    &-left {
      color: rgb(36, 40, 51);
      font-size: 30px;
      line-height: 42px;
      font-weight: bold;
    }
    &-right {
      margin-left: 40px;
      color: #356dff;
      border-color: #356dff;
      background-color: #fff;
      cursor: unset;
    }
  }
  &-content {
    margin-top: 24px;
    width: 100%;
    // height: 2800px;
    // max-width: 1263px;
    text-align: left;
    background: #fff;

    &-card {
      position: sticky;
      top: 91px;
      z-index: 1000 !important;
      background: rgb(255, 255, 255);
      // display: flex;
      transition: height 0s ease-in-out;
      &-one {
        display: flex;
        align-items: center;
        padding-left: 40px;
        width: 320px;
        height: inherit;
        background: #fff;
      }
      &-other {
        min-width: 236px;
        background: #fff;
        color: #fff;
        height: inherit;
        display: flex;
        align-items: center;
        // background: linear-gradient(270deg, #75afe2 0%, #9dcdf3 100%);
        &-col {
          // min-height: 368px;
          min-width: 236px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    &-mytable {
      overflow: hidden;
    }
  }
}
</style>
<style lang="scss" scoped>
.flex-table{
  display: flex;
  flex-direction: column;
}

// 底部按钮
.bottom-z{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
}
.bottom-one{
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  color: #fff;
  align-self: start;
}

  @media only screen and (min-width:1199px){
    .show-mtable{
      display: none;
    }
    .show-table{
      display: unset;
    }
    .main-content{
      width: 1263px !important;
    }
  }
  @media only screen and (max-width:900px){
    .bottom-box-content-box{
      display: flex;
      flex-direction: column;
      text-align: center !important;
      align-items: center;
      justify-content: center;
    }
    .bottom-box-content-box-r{
      display: flex;
      flex-direction: column;
      text-align: center !important;
      align-items: center;
      justify-content: center;
    }
    .bottom-box-content-box-r-left{
      display: flex;
      flex-direction: column;
      text-align: center !important;
      padding-left: unset;
      align-items: center;
      justify-content: center;
    }
    .bottom-box-content-box-r-right{
      display: flex;
      flex-direction: column;
      text-align: center !important;
      align-items: center;
      padding-right: 0;
      justify-content: center;
    }
    .cbefore{
      margin-right: 5px;
    }
    .cbefore::before {
      width: 6px;
      height: 6px;
      margin-top: 0;
      margin-right: 0;
      background: #9399a5;
      border-radius: 50%;
      content: "";
    }

    .bottom-one{
      margin-bottom: 14px;
      font-weight: 600;
      font-size: 30px;
      line-height: 42px;
      color: #fff;
      align-self: center;
    }
    .bottom-z{
      padding-left: unset;
    }
  }

  // 卡片缩小时的鼠标指针效果
  .tobuy{
    cursor: pointer;
  }
</style>
